// // assets

import StackedBarChartOutlinedIcon from "@mui/icons-material/StackedBarChartOutlined";

// import {
//   IconTypography,
//   IconPalette,
//   IconShadow,
//   IconWindmill,
// } from "@tabler/icons";

// // constant
// const icons = {
//   IconTypography,
//   IconPalette,
//   IconShadow,
//   IconWindmill,
// };

// ==============================|| UTILITIES MENU ITEMS ||============================== //

// assets

import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconCategory,
} from "@tabler/icons";

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconCategory,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const deals = {
  id: "deals",
  title: "Manage Deals",
  type: "group",
  children: [
    {
      id: "completed deal analysis",
      title: "Completed Deal Analysis",
      type: "item",
      url: "dealanalysis",
      icon: StackedBarChartOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "draft deal analysis",
      title: "Draft deal Analysis",
      type: "item",
      url: "dealdraftanalysis",
      icon: StackedBarChartOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "shared deal analysis",
      title: "Shared Deal Analysis",
      type: "item",
      url: "shareddealanalysis",
      icon: StackedBarChartOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default deals;

// const deals = {
//   id: "deals",
//   title: "Deals",
//   type: "group",
//   children: [
//     {
//       id: "deal analysis",
//       title: "Deal Analysis",
//       type: "collapse",
//       icon: icons.IconKey,
//       children: [
//         {
//           id: "payment deal analysis",
//           title: "Payment Deal Analysis",
//           type: "item",
//           url: "dealanalysis",
//           icon: icons.IconTypography,
//           breadcrumbs: false,
//         },
//         {
//           id: "draft deal analysis",
//           title: "Draft deal Analysis",
//           type: "item",
//           url: "dealdraftanalysis",
//           icon: icons.IconPalette,
//           breadcrumbs: false,
//         },
//         {
//           id: "shared deal analysis",
//           title: "Shared Deal Analysis",
//           type: "item",
//           url: "shareddealanalysis",
//           icon: icons.IconShadow,
//           breadcrumbs: false,
//         },
//         // {
//         //     id: 'icons',
//         //     title: 'Icons',
//         //     type: 'collapse',
//         //     icon: icons.IconWindmill,
//         //     children: [
//         //         {
//         //             id: 'tabler-icons',
//         //             title: 'Tabler Icons',
//         //             type: 'item',
//         //             url: '/icons/tabler-icons',
//         //             breadcrumbs: false
//         //         },
//         //         {
//         //             id: 'material-icons',
//         //             title: 'Material Icons',
//         //             type: 'item',
//         //             url: '/icons/material-icons',
//         //             breadcrumbs: false
//         //         }
//         //     ]
//         // }
//       ],
//     },
//   ],
// };

// export default deals;
