// assets

import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconCategory,
} from "@tabler/icons";

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconCategory,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const admins = {
  id: "category",
  title: "Category",
  type: "group",
  children: [
    {
      id: "category",
      title: "Manage Categories",
      type: "item",
      url: "/managecategory",
      icon: icons.IconCategory,
      breadcrumbs: false,
    },
  ],
};

export default admins;
