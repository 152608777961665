// assets

import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconQuestionMark,
} from "@tabler/icons";

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconQuestionMark,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const questions = {
  id: "questions",
  title: "Questions",
  type: "group",
  children: [
    {
      id: "questions-panel",
      title: "Manage Questions",
      type: "item",
      url: "/managequestion",
      icon: QuestionAnswerOutlinedIcon,
      breadcrumbs: false,
    },
    // {
    //     id: 'util-color',
    //     title: 'Color',
    //     type: 'item',
    //     url: '/utils/util-color',
    //     icon: icons.IconPalette,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'util-shadow',
    //     title: 'Shadow',
    //     type: 'item',
    //     url: '/utils/util-shadow',
    //     icon: icons.IconShadow,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'icons',
    //     title: 'Icons',
    //     type: 'collapse',
    //     icon: icons.IconWindmill,
    //     children: [
    //         {
    //             id: 'tabler-icons',
    //             title: 'Tabler Icons',
    //             type: 'item',
    //             url: '/icons/tabler-icons',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'material-icons',
    //             title: 'Material Icons',
    //             type: 'item',
    //             url: '/icons/material-icons',
    //             breadcrumbs: false
    //         }
    //     ]
    // }
  ],
};

export default questions;
