import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const Protectedroutes = ({ component, name }) => {
  const loggedIn = useSelector((state) => state.currentUser.loggedIn);
  const role = useSelector(
    (state) => state.currentUser?.userData?.user?.role_id
  );
  console.log("role");
  console.log(role);
  console.log(name);

  if (name && name === "SuperAdmin") {
    console.log("here");
    if (loggedIn && role === 1) {
      return component;
    } else {
      return <Navigate to="/" />;
    }
  }
  console.log("here1");
  return loggedIn ? component : <Navigate to="/login" />;
};

export default Protectedroutes;
