// assets

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
} from "@tabler/icons";

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const users = {
  id: "users",
  title: "Users",
  type: "group",
  children: [
    {
      id: "users-panel",
      title: "Manage Users",
      type: "item",
      url: "/manageuser",
      icon: PersonOutlineOutlinedIcon,
      breadcrumbs: false,
    },
    // {
    //     id: 'util-color',
    //     title: 'Color',
    //     type: 'item',
    //     url: '/utils/util-color',
    //     icon: icons.IconPalette,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'util-shadow',
    //     title: 'Shadow',
    //     type: 'item',
    //     url: '/utils/util-shadow',
    //     icon: icons.IconShadow,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'icons',
    //     title: 'Icons',
    //     type: 'collapse',
    //     icon: icons.IconWindmill,
    //     children: [
    //         {
    //             id: 'tabler-icons',
    //             title: 'Tabler Icons',
    //             type: 'item',
    //             url: '/icons/tabler-icons',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'material-icons',
    //             title: 'Material Icons',
    //             type: 'item',
    //             url: '/icons/material-icons',

    //             breadcrumbs: false
    //         }
    //     ]
    // }
  ],
};

export default users;
