// assets
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";

import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
} from "@tabler/icons";

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const admins = {
  id: "admins",
  title: "Admins",
  type: "group",
  children: [
    {
      id: "admins",
      title: "Manage Admins",
      type: "item",
      url: "/manageadmin",
      icon: SupervisorAccountOutlinedIcon,
      breadcrumbs: false,
    },
    //     {
    //         id: 'util-color',
    //         title: 'Color',
    //         type: 'item',
    //         url: '/utils/util-color',
    //         icon: icons.IconPalette,
    //         breadcrumbs: false
    //     },
    //     {
    //         id: 'util-shadow',
    //         title: 'Shadow',
    //         type: 'item',
    //         url: '/utils/util-shadow',
    //         icon: icons.IconShadow,
    //         breadcrumbs: false
    //     },
    //     {
    //         id: 'icons',
    //         title: 'Icons',
    //         type: 'collapse',
    //         icon: icons.IconWindmill,
    //         children: [
    //             {
    //                 id: 'tabler-icons',
    //                 title: 'Tabler Icons',
    //                 type: 'item',
    //                 url: '/icons/tabler-icons',
    //                 breadcrumbs: false
    //             },
    //             {
    //                 id: 'material-icons',
    //                 title: 'Material Icons',
    //                 type: 'item',
    //                 url: '/icons/material-icons',
    //                 breadcrumbs: false
    //             }
    //         ]
    //     }
  ],
};

export default admins;
