import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import Protectedroutes from "./protectedroutes";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
const Typography = Loadable(lazy(() => import("views/utilities/Typography")));
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const AdminsTable = Loadable(lazy(() => import("views/utilities/adminTable")));
const UsersTable = Loadable(lazy(() => import("views/utilities/userTable")));
const QuestionsTable = Loadable(
  lazy(() => import("views/utilities/questionTable"))
);
const DealAnalysisTable = Loadable(
  lazy(() => import("views/utilities/dealanalysis"))
);
const DealDraftAnalysisTable = Loadable(
  lazy(() => import("views/utilities/dealdraftanalysis"))
);
const SharedDealAnalysisTable = Loadable(
  lazy(() => import("views/utilities/shareddealanalysis"))
);

const NotificationTable = Loadable(
  lazy(() => import("views/utilities/notificationTable"))
);

const CategoryTable = Loadable(
  lazy(() => import("views/utilities/categoryTable"))
);

const PaymentLogsTable = Loadable(
  lazy(() => import("views/utilities/paymentlogsTable"))
);

const VideoTable = Loadable(lazy(() => import("views/utilities/videosTable")));

const PublicCalendly = Loadable(
  lazy(() => import("views/utilities/publicCalendly"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <Protectedroutes component={<MainLayout />} />,
  children: [
    {
      path: "/",
      element: <Protectedroutes component={<DashboardDefault />} />,
    },
    {
      //path: 'utils',

      path: "manageadmin",
      element: (
        <Protectedroutes component={<AdminsTable />} name="SuperAdmin" />
      ),
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <Protectedroutes component={<DashboardDefault />} />,
        },
      ],
    },
    {
      //path: 'utils',
      children: [
        {
          path: "util-typography",
          element: <Protectedroutes component={<Typography />} />,
        },
      ],
    },
    {
      //path: 'utils',
      children: [
        {
          path: "manageuser",
          element: <Protectedroutes component={<UsersTable />} />,
        },
      ],
    },
    {
      //path: 'utils',
      children: [
        {
          path: "managequestion",
          element: <Protectedroutes component={<QuestionsTable />} />,
        },
      ],
    },
    {
      //path: 'utils',
      children: [
        {
          path: "dealanalysis",
          element: <Protectedroutes component={<DealAnalysisTable />} />,
        },
      ],
    },
    {
      //path: 'utils',
      children: [
        {
          path: "dealdraftanalysis",
          element: <Protectedroutes component={<DealDraftAnalysisTable />} />,
        },
      ],
    },
    {
      //path: 'utils',
      children: [
        {
          path: "shareddealanalysis",
          element: <Protectedroutes component={<SharedDealAnalysisTable />} />,
        },
      ],
    },
    {
      //path: 'utils',
      children: [
        {
          path: "notifications",
          element: <Protectedroutes component={<NotificationTable />} />,
        },
      ],
    },
    {
      children: [
        {
          path: "managecategory",
          element: <Protectedroutes component={<CategoryTable />} />,
        },
      ],
    },
    {
      children: [
        {
          path: "paymentlogs",
          element: <Protectedroutes component={<PaymentLogsTable />} />,
        },
      ],
    },
    {
      children: [
        {
          path: "managevideos",
          element: <Protectedroutes component={<VideoTable />} />,
        },
      ],
    },

    {
      path: "utils",
      children: [
        {
          path: "util-color",
          element: <Protectedroutes component={<UtilsColor />} />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "util-shadow",
          element: <Protectedroutes component={<UtilsShadow />} />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "tabler-icons",
          element: <Protectedroutes component={<UtilsTablerIcons />} />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <Protectedroutes component={<UtilsMaterialIcons />} />,
        },
      ],
    },
    {
      path: "sample-page",
      element: <Protectedroutes component={<SamplePage />} />,
    },
  ],
};

export default MainRoutes;
