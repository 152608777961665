// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import { menuItemsSuperAdmin, menuItemsAdmin } from "menu-items";
import { useDispatch, useSelector } from "react-redux";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const role = useSelector(
    (state) => state.currentUser?.userData?.user?.role_id
  );
  const menuItem = role === 1 ? menuItemsSuperAdmin : menuItemsAdmin;
  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      // case 'collapse':
      //     return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
