import dashboard from "./dashboard";
import admins from "./admins";
import deals from "./deals";
import users from "./users";
import questions from "./questions";
import categories from "./category";
import paymentlogs from "./paymentlogs";
import videos from "./video";
import notifications from "./notifications";

// ==============================|| MENU ITEMS ||============================== //

export const menuItemsSuperAdmin = {
  items: [
    dashboard,
    admins,
    users,
    categories,
    questions,
    videos,
    paymentlogs,
    deals,
  ],
};

export const menuItemsAdmin = {
  items: [dashboard, users, categories, questions, videos, paymentlogs, deals],
};
